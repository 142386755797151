import React,{useEffect, useState} from 'react'
import { Link/*, Script, ScriptStrategy*/, graphql } from "gatsby"

import axios from 'axios';
import Layout from "../components/layout"
import ThemeContext from "../context/ThemeContext"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import useWindowSize from '../hooks/useGatsbyWindowSize'
import { useMediaQuery } from 'react-responsive'



const ContactPage = ({ data }) => {

  //console.log(data);

  const [jsonTeam, setJsonTeam] = useState(
    Array.from({ length: 1 }).map((_, index) =>  <div className="team"></div>)
  );

  const [jsonContent, setJsonContent] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const mobile = useMediaQuery({ query: `(max-width: 767px)` });
  const {width,height} = useWindowSize();
//  var image = data.photos.edges[0];
  //console.log(image);

  function loadHtml(url) {
  var _json = url;
  axios.get(_json)
.then(function (response) {
  var _jsonContent =  response.data.content;
  var _jsonTeam = response.data.team
  //console.log(_jsonTeam);
  setJsonTeam(_jsonTeam);
  setJsonContent(_jsonContent);
  setIsLoaded(true);
});
}


  useEffect(() => {
    console.log('loaded')
      loadHtml('https://discover.lilleymansion.com/app/v1/content/team');
  },[]);

  useEffect(() => {
    setIsMobile(mobile);

    if(mobile){
    //  image = data.photos.edges[1];
      //console.log(image);
    }
  },[]);




  return (

    <ThemeContext.Consumer>
        {theme => (
    <div id="page">
    <div id="page-wrapper" className="pt-5">


    <div className="mt-5 mb-5 text-center p-2 m-2">
    <h1 className="titling pt-5">CONTACT US</h1>

    <div className="row center pt-3">
    <div className="col-12 text-center" dangerouslySetInnerHTML={{ __html: jsonContent }} />
    </div>
    <div className="row">
    {jsonTeam.map((item, index) => (

    item.visible_contact ?  <div className="col-12" key={'s'+index}><img width="90" className="rounded-circle" src={'https://discover.lilleymansion.com/storage/app/media' + item.image} alt={item.name}/><h3 className="text-center">{item.name}<br/><small>{item.position}</small></h3><p className="text-center" >{item.email}</p></div>
      :
      ''

    ))}
    </div>


    </div>
    </div>
    </div>
  )}
      </ThemeContext.Consumer>

  )
}

ContactPage.Layout = Layout

export default ContactPage

/*export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }

    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "history"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 1200
                  quality: 80
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
  }
`*/
